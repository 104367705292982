<template>
  <RmaGuestNewReturn />
</template>

<script>
import RmaGuestNewReturn from "@/esf_utrecht_bankxl/core/components/rma/guest/NewRequest.vue";
export default {
  name: "RmaGuestNewReturnView",
  components: {
    RmaGuestNewReturn,
  },
};
</script>
