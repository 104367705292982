<template>
  <RmaGuestReturnRequest />
</template>

<script>
import RmaGuestReturnRequest from "@/esf_utrecht_bankxl/core/components/rma/guest/StartRequest.vue";
export default {
  name: "RmaGuestReturnRequestView",
  components: {
    RmaGuestReturnRequest,
  },
};
</script>
