<template>
  <RmaUserNewReturn />
</template>

<script>
import RmaUserNewReturn from "@/esf_utrecht_bankxl/core/components/rma/user/NewRequest.vue";
export default {
  name: "RmaUserNewReturnView",
  components: {
    RmaUserNewReturn,
  },
};
</script>
