<template>
    <section class="rma-payment">
        <b-container>
            <Breadcrumbs />
            
            <h2 v-if="returnOrder">{{$t(
                'rma_return_label_payment_header', 
                {returnNumber: returnOrder.number}
            )}}</h2>
            
            <div class="messages"  v-if="messages && messages.length">
                <div class="alert alert-danger" v-for="(message, index) in dangerMessages" :key="index">
                    <h4>{{$t('Oops')}}</h4>
                    <div>{{$t(message.message)}}</div>
                </div>
                <div class="alert alert-success" v-for="(message, index) in successMessages" :key="index">
                    <div>{{$t('message.message')}}</div>
                </div>
            </div>
            <div class="payment-methods-wrapper">
                <div class="payment-methods" v-if="quoteId && paymentMethods && paymentMethods.length">
                    <div class="header">
                        <strong>{{$t('rma_payment_choose_method_header')}}</strong>
                        <div class="errors">
                            <small class="text-danger" v-if="errors.payment_method">
                                {{errors.payment_method}}
                            </small>
                        </div>
                    </div>
                    <div class="methods">
                    
                        <div class="payment-method" v-for="paymentMethod in paymentMethods" :key="paymentMethod.code">
                            <div class="form-check">
                                <input type="radio" :value="paymentMethod.code" class="form-check-input" :id="'payment-method-' + paymentMethod.code" v-model="currentPaymentMethod.code" @change="setPaymentMethod" />
                                <label class="form-check-label" :for="'payment-method-' + paymentMethod.code">
                                    <div class="payment-icon">
                                        <img
                                          :src="`${app_url}/media/graphql/icons/50x32/${paymentMethod.code}.png`"
                                          :alt="paymentMethod.label"
                                        />
                                    </div>
                                    <span class="method-label">{{paymentMethod.label}}</span>
                                </label>
                            </div>
                            <div class="additional" v-show="paymentMethod.code === currentPaymentMethod.code" v-if="paymentMethod.additional_fields && paymentMethod.additional_fields.length">
                                <div class="additional-field" v-for="additionalField in paymentMethod.additional_fields" :key="additionalField.code">
                                    <template v-if="additionalField.type === 'select'">
                                        <div class="form-group row">
                                        
                                            <label class="text-right col-form-label col-md-5 col-md-4 col-lg-3" :for="paymentMethod.code + '-' + additionalField.code">{{additionalField.label}}</label>
                                            
                                            <div class="col-md-7 col-md-8 col-lg-5">
                                                <select v-model="currentPaymentMethod.additional[additionalField.code]" class="form-control" :class="{'is-invalid': errors[paymentMethod.code] && errors[paymentMethod.code][additionalField.code]}">
                                                    <option value="">{{$t('Select')}}</option>
                                                    <option :value="option.value" v-for="option in additionalField.options" :key="option.value">{{option.label}}</option>
                                                </select>
                                                
                                                <small class="text-danger" v-if="errors[paymentMethod.code] && errors[paymentMethod.code][additionalField.code]">
                                                    {{errors[paymentMethod.code][additionalField.code]}}
                                                </small>
                                            </div>
                                        </div>
                                    </template>
                                </div>  
                            </div>
                        </div>
                        
                    </div>
                    
                    
                    <div class="payment-footer text-right">
                        <button class="btn btn-success" @click.prevent="submit">
                            {{$t('rma_payment_button')}}
                        </button>
                    </div>
                </div>
            </div>
            
        </b-container>
    </section>
</template>

<script>
    import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
    import config from "@config";
    import { getReturnById, getReturnLabelPaymentData, sendPaymentRequest } from "@/esf_utrecht_bankxl/store/modules/rma";
    
    
    export default {
        components: {
            Breadcrumbs
        },
        
        metaInfo() {
            return {
                meta: [
                    {
                        name: "title",
                        content: "Retourlabel betalen voor retour #" + this.returnOrder.number,
                    },
                    {
                        name: "keywords",
                        content: "",
                    },
                    {
                        name: "description",
                        content: "",
                    },
                ],
                title: "Retourlabel betalen voor retour #" + this.returnOrder.number,
                link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
            };
        },
        
        computed: {
            dangerMessages() {
                return this.messages.filter((item) => {
                    return item.type === 'danger';
                });
            },
            successMessages() {
                return this.messages.filter((item) => {
                    return item.type === 'success';
                });
            }
        },
        
        data() {
            return {
                app_url: config.app_url,
                returnOrder: {},
                quoteId: null,
                paymentMethods: [],
                currentPaymentMethod: {
                    code: '',
                    additional: {}
                },
                messages: [],
                errors: {
                    payment_method: null
                }   
            }
        },
                
        mounted() {
        
            getReturnById(this.$route.query.return).then((res) => {
                if (res.data.return === null && typeof res.errors !== 'undefined') {
                    this.addMessage({
                        message: this.$t('rma_payment_return_error'),
                        type: 'danger'
                        
                    });
                    const bcrumb = {
                        current: "Retourlabel betalen",
                        routes: [],
                    };
                    this.$store.commit("breadcrumbs/set", bcrumb);
                    return;
                }
                
                this.returnOrder = res.data.return;
                
                this.getPaymentData();
                
                const bcrumb = {
                    current: "Retourlabel betalen voor retour #" + this.returnOrder.number,
                    routes: [],
                };

                this.$store.commit("breadcrumbs/set", bcrumb);
            });       
            
        },
        
        methods: {
            formatDateTime(datetime) {
                let formattedDate = new Date(datetime).toLocaleDateString(
                    "nl-NL",
                    this.dateFormat
                );
                return formattedDate;
            },
            
            addMessage(message) {
                this.messages.push(message);
            },
            
            async getPaymentData() {
                let paymentData = await getReturnLabelPaymentData(this.returnOrder.id).catch((err) => {
                    console.error(err);
                    this.addMessage({
                        message: this.$t('rma_payment_return_quote_error'),
                        type: 'danger'
                        
                    });
                    return;
                });
                
                if (paymentData.data.createReturnLabelQuote === null && typeof paymentData.errors !== 'undefined') {
                    this.addMessage({
                        message: this.$t('rma_payment_return_quote_error'),
                        type: 'danger'
                        
                    });
                    return;
                }
                
                this.quoteId = paymentData.data.createReturnLabelQuote.id;
                this.paymentMethods = paymentData.data.createReturnLabelQuote.available_payment_methods;
                this.$set(this.currentPaymentMethod, 'additional', {});
            },
            
            setPaymentMethod() {
                this.errors.payment_method = null;
                
                let paymentMethod = this.paymentMethods.filter((method) => {
                    return method.code === this.currentPaymentMethod.code;
                }).shift();
                
                let additionalFields = paymentMethod.additional_fields;
                
                if (
                    typeof additionalFields == 'undefined' || 
                    typeof additionalFields.length === 'undefined' ||
                    additionalFields.length <= 0
                ) {
                    this.currentPaymentMethod.additional = {};
                    this.$set(this.currentPaymentMethod, 'additional', {});
                    return;
                }
                let additionFieldCodes = {};                
                additionalFields.forEach((field) => {
                    this.currentPaymentMethod.additional[field.code] = '';
                    additionFieldCodes[field.code] = '';
                    
                });
                
                this.$set(this.currentPaymentMethod, 'additional', additionFieldCodes);
            },
            
            async submit() {
                this.messages = [];
                
                if (!this.validate()) {
                    return;
                }
                
                console.log('Sending request');
                
                let additionalPaymentData = [];
                
                let keys = Object.keys(this.currentPaymentMethod.additional);
                keys.forEach((key) => {
                    additionalPaymentData.push({
                        code: key,
                        value: this.currentPaymentMethod.additional[key]
                    });
                });
                                
                let response = await sendPaymentRequest({
                    return_id: this.returnOrder.id,
                    cart_id: this.quoteId,
                    payment_method: {
                        code: this.currentPaymentMethod.code,
                        additional_data: additionalPaymentData
                    }
                });
                
                if (response.data.placeReturnLabelOrder === null && typeof response.errors !== 'undefined') {
                    this.addMessage({
                        message: this.$t('rma_payment_place_error'),
                        type: 'danger'
                    });
                }
                
                window.location.href = response.data.placeReturnLabelOrder.redirect_url;
            },
            
            validate() {
                this.errors.payment_method = null;
                
                if (this.currentPaymentMethod.code === null || this.currentPaymentMethod.code.trim().length === 0) {
                    this.errors.payment_method = this.$t('rma_payment_error_method');
                    return false;
                }
                
                let paymentMethod = this.paymentMethods.filter((method) => {
                    return method.code === this.currentPaymentMethod.code;
                }).shift();
                
                let additionalFields = paymentMethod.additional_fields;
                let fieldErrors = {}
                
                if (
                    typeof additionalFields !== 'undefined' &&
                    typeof additionalFields.length !== 'undefined' &&
                    additionalFields.length > 0
                ) {
                    additionalFields.forEach((field) => {
                        if (
                            typeof this.currentPaymentMethod.additional[field.code] === 'undefined' || 
                            this.currentPaymentMethod.additional[field.code] === null ||
                            this.currentPaymentMethod.additional[field.code].trim().length === 0
                        ) {
                            fieldErrors[field.code] = this.$t('rma_payment_required_field_error');
                        }
                    });
                }
                
                this.$set(this.errors, paymentMethod.code, fieldErrors);
                
                if (Object.keys(fieldErrors).length > 0) {
                    return false;
                }
                
                return true;
            }
        }
    }
</script>
