<template>
  <RmaPaymentRequest />
</template>

<script>
import RmaPaymentRequest from "@/esf_utrecht_bankxl/core/components/rma/PaymentRequest.vue";
export default {
  name: "RmaPaymentRequestView",
  components: {
    RmaPaymentRequest,
  },
};
</script>
